.sidebar-main-div{
    width: 100%;
    height: 100%;
    min-height: 100vh;

    overflow: auto;

    background: #FF6B17;
}

.sidebar-main-div
.sidebar-logo-container{
    width: 100%;
    height: 120px;
    border-bottom: 1px solid #C8CBD9;

    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-main-div
.sidebar-logo-container
.main-logo-sidebar{
    width: 166.135px;
    height: 57.619px;
}

.sidebar-main-div
.main-content-sidebar{
    width: 100%;
    box-sizing: border-box;
    padding: 29px 23px;
}

.sidebar-main-div
.main-menu-div{
    width: 100%;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebar-main-div
.main-menu-div
.menu-title{
    margin-left: 23.6px;
    margin-bottom: 12px;
    text-align: start;
    text-transform: uppercase;

    color: #082431;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.6875rem;
    letter-spacing: 0.0625rem;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div{
    width: 100%;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div
.sidebar-item-div{
    text-decoration: none;
    color: #000;
    width: 100%;
    min-height: 49px;
    height: fit-content;

    box-sizing: border-box;
    padding: 14px 23px;
    padding-left: 58px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14.3px;

    position: relative;

    overflow: hidden;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div
.active-link{
    background-color: #F6C19B;
    border-radius: 5px;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div
.sidebar-item-div
.item-icon{
    display: none;
    position: absolute;
    font-size: 21px;
    color: #000;

    transform: translate(0, -50%);

    top: 50%;
    left: 23px;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div
.active-link
.item-icon{
    display: initial;
}

.sidebar-main-div
.main-content-sidebar
.main-menu-div
.menu-items-div
.sidebar-item-div
.sidebar-item-text{
    color: #682B00;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;

    text-transform: capitalize;
}